import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { AlertServiceService } from './../../../core/services/alert-service.service';
import { AuthService } from './../auth.service';
import { BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { error } from 'console';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: false } },
  ],
})
export class LoginComponent implements OnInit {
  public email: string;
  public password: string;
  timeLeft: number = 600;
  minLeft: number;
  secLeft: number;
  interval;
  public locales = [
    { label: 'English', value: 'en' },
    { label: 'French', value: 'fr' },
    { label: 'Hindi', value: 'hi' },
    {label:'Bangali', value:'ba'},
    {label:'Sinhala',value:'si'},
    {label:'Mandarin',value:'ma'}
  ];

  public selectedLanguage: any;
  public locale = this.locales[0].value;
  public otpDiv: boolean;
  public otp: number;
  distinctRoles: Array<any>=[];
  user: any;
  loginCheck:boolean=false;
  roleWiseData: any;
  public modalRef: BsModalRef;
  accountNumber: any;
  accType: string;
  isShowPassword:boolean = false;
  isResendOTP : boolean = false;
  ipAddress: any;
  isLoader:boolean = false;

  constructor(private translocoService: TranslocoService,
    private service: AuthService,
    private router: Router,
    private alertService: AlertServiceService,
    private modalService: BsModalService
  ) {
    // this.detectedLocale = this.getUsersLocale('en-US');
    this.selectedLanguage = localStorage.getItem('selectedLanguage');
    // generate a regex from the locales we support
    const supportedRegex = new RegExp('^' + this.locales.map((l) => l.value.substring(0, 2)).join('|^'));
    // check if the user's preferred language is supported and if so, use it.
    if (this.selectedLanguage) {
      // check if the user's preferred language is supported and if so, use it.
      if (this.selectedLanguage.match(supportedRegex)) {
        this.updateLocale(this.selectedLanguage);
      }
    }
  }

  public ngOnInit(): void {
    this.otpDiv = false;
  }

  signIn() {
    this.isLoader = true;
    this.ipAddress = null
    sessionStorage.setItem('qrip',null)
    this.logIn()
    //Below code are for IP Restrication
    // this.service.getIPAddress().subscribe((res:any)=>{
    //   console.log('IP Address',res)
    //   this.ipAddress = res.ip;
    //   sessionStorage.setItem('qrip',this.ipAddress)
    //   console.log('IP Address',this.ipAddress)
    //   this.logIn()
    // },(error:any)=>{
    //   sessionStorage.setItem('qrip',null)
    //   this.logIn()
    // })
  }

  giveSuggestion(value){
    this.loginCheck=!value;
  }

  logIn(){
    const data = {
      emailId: this.email,
      password: this.password,
      ipAddress:this.ipAddress
    };
    this.service.postLogin(data)
      .subscribe((res:any) => {
        // below are the temp code for bypass otp
      //   this.service.doLoginUser(res.data.results[0].token)
      // this.user =this.service.getprivileges();
      // this.service.getAssignedUserRoles().subscribe(res=>{
      //   this.distinctRoles=res.data.results[0];
      //   let count = 0
      //   this.distinctRoles.forEach(ele=>{
      //     let obj = {
      //       "roleName":ele.roleName,
      //       "ipAddress":this.ipAddress
      //     }
      //     this.service.checkIPUserRole(obj).subscribe((res:any)=>{
      //       count++
      //       if(this.distinctRoles.length == count){
      //         this.isLoader = false
      //         this.showAssignedUserRoles()
      //       }
      //     },(error)=>{
      //       let ind = this.distinctRoles.findIndex(e=> e.roleName == ele.roleName)
      //       if(ind != -1){
      //         this.distinctRoles.splice(ind,1)
      //       }
      //       if(this.distinctRoles.length == count){
      //         if(this.distinctRoles.length != 0){
      //         this.isLoader = false
      //         this.showAssignedUserRoles()
      //       }else{
      //         this.isLoader = false
      //         this.otpDiv = false
      //         this.alertService.sweetalertError('Access Denied !');
      //         this.service.logout();
      //       }
      //       }
      //     })
      //   })
      //  })
      //  code end

        // below code temp comment
        this.isLoader = false;
        this.otpDiv = true;
        this.alertService.sweetalertMasterSuccess(res['status']['message'], '');
        this.interval = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft--;
            this.minLeft = Math.floor(this.timeLeft / 60);
            this.secLeft = Math.floor(this.timeLeft % 60);
          }
          else {
            this.isResendOTP = true;
          }
        }, 1000);
      },
        (err) => {
          this.isLoader = false;
          console.log(err.error);
          if (err instanceof HttpErrorResponse) {
            this.alertService.sweetalertError(
              err.error.status?.message,
            );
            if(err.error.status?.code == "4002"){
            this.router.navigate(['/Set-Password'])
            }
          }
        },
      );
  }

  submitOTP() {
    this.isLoader = true;
    const data = {
      emailId: this.email,
      otp: this.otp,
    };
    this.service.postOTP(data)
      .subscribe((res) => {
       this.user =this.service.getprivileges();
       sessionStorage.setItem('%qr',this.service.enObject(this.user.UserDetails))
       //New Code
       let obj={
        // "ipAddress":sessionStorage.getItem('qrip')
        "ipAddress":null
       }
       this.service.getAssignedUserRoles(obj).subscribe(res=>{
        this.distinctRoles=res.data.results[0];
        if(this.distinctRoles.length == 0){
                this.isLoader = false
                this.otpDiv = false
                this.alertService.sweetalertError('Access Denied !');
                this.service.logout();
              }else{
                if(this.distinctRoles.length > 1){
                this.service.getLastLoginRoleType(this.distinctRoles[0].globalUserMasterId).subscribe((resType:any)=>{
                  console.log('Found chnage Role Type Name',resType.data.results[0])
                  if(resType.data.results[0] == 'Employee' || resType.data.results[0] == 'Admin' || resType.data.results[0] == 'Global'){   
                  this.showLastLoginRoles(resType.data.results[0])
                }else{    
                  this.showAssignedUserRoles()
                }
                },(error:any)=>{
                  this.showAssignedUserRoles()
                 })
                }else{
                  this.showAssignedUserRoles()
                }
              }

        // let count = 0
        // this.distinctRoles.forEach(ele=>{
        //   let obj = {
        //     "roleName":ele.roleName,
        //     "ipAddress":this.ipAddress
        //   }
        //   this.service.checkIPUserRole(obj).subscribe((res:any)=>{
        //     count++
        //     if(this.distinctRoles.length == count){
        //       this.isLoader = false
        //       this.showAssignedUserRoles()
        //     }
        //   },(error)=>{
        //     let ind = this.distinctRoles.findIndex(e=> e.roleName == ele.roleName)
        //     if(ind != -1){
        //       this.distinctRoles.splice(ind,1)
        //     }
        //     if(this.distinctRoles.length == count){
        //       if(this.distinctRoles.length != 0){
        //       this.isLoader = false
        //       this.showAssignedUserRoles()
        //     }else{
        //       this.isLoader = false
        //       this.otpDiv = false
        //       this.alertService.sweetalertError('Access Denied !');
        //       this.service.logout();
        //     }
        //     }
        //   })
        //   //call API { count ++ if(count == this.distinctRoles.lenght){ call role function}}
        // })
       },(error:any)=>{
        this.isLoader = false;
       })
//new data from local file to check user
      //   this.service.getAssignedUserRoles().subscribe(res=>{
      //     this.isLoader = false;
      //     this.distinctRoles=res.data.results[0];
      //    let globalRolePresent =  this.distinctRoles.find(x=>x.type=='Global')
      //    let adminRolePresent =  this.distinctRoles.find(x=>x.type=='Admin')
      //    sessionStorage.setItem('selectedCountry','')
      //    sessionStorage.setItem('selectedGroup','')
      //    sessionStorage.setItem('selectedRole','')
      //    sessionStorage.setItem('selectedCompany','')
      //    let empData = this.distinctRoles.find(x=>x.type=='Employee')
      //    sessionStorage.setItem('empComp',JSON.stringify(empData.xtenantId))
      //   if(this.distinctRoles.length==1){
      //    sessionStorage.setItem('%qr',JSON.stringify(this.distinctRoles[0]))
      //   sessionStorage.setItem('%qrCopy',JSON.stringify(this.distinctRoles[0]));
      //   sessionStorage.setItem('id',this.distinctRoles[0].userRoleId)
      //   localStorage.setItem('employeeMasterId',this.user.UserDetails.employeeMasterId);
      //   this.service.shareData.next();
      //   this.router.navigate(['/employee-master/employee-dashboard']);
      //    }
      //  else if(globalRolePresent){
      //   if(globalRolePresent.globalCompanyMasterIdList.split(',').length>1){
      //     this.service.shareRoleName.next(globalRolePresent.roleName)
      //     sessionStorage.setItem('selectedRole',globalRolePresent.roleName)
      //     sessionStorage.setItem('%qr',JSON.stringify(globalRolePresent));
      //     sessionStorage.setItem('%qrCopy',JSON.stringify(globalRolePresent));
      //     sessionStorage.setItem('id',globalRolePresent.userRoleId)
      //     this.router.navigate(['/userGroupSelectPage']);
      //   }else{
      //     sessionStorage.setItem('%qr',JSON.stringify(globalRolePresent));
      //     sessionStorage.setItem('%qrCopy',JSON.stringify(globalRolePresent));
      //     sessionStorage.setItem('globalCompanyList',globalRolePresent.globalCompanyMasterIdList);
      //     this.service.shareData.next();
      //     //changes for single company
      //     let tempRole = JSON.parse(sessionStorage.getItem('%qr'));
      //       tempRole.groupName=globalRolePresent.xtenantId
      //       tempRole.globalCompanyMasterId =JSON.parse(tempRole.globalCompanyMasterIdList)
      //       sessionStorage.setItem('%qr',JSON.stringify(tempRole))
      //       this.service.shareCompName.next(globalRolePresent.companyName)
      //       this.service.shareRoleName.next(globalRolePresent.roleName)
      //       sessionStorage.setItem('selectedRole',globalRolePresent.roleName)
      //     this.router.navigate(['/dashboard']);
      //   }
      //   }else if(adminRolePresent){
      //     sessionStorage.setItem('id',adminRolePresent.userRoleId)
      //     sessionStorage.setItem('%qr',JSON.stringify(adminRolePresent));
      //       sessionStorage.setItem('%qrCopy',JSON.stringify(adminRolePresent));
      //     if(adminRolePresent.globalCompanyMasterIdList.split(',').length>1){
      //       this.service.shareRoleName.next(adminRolePresent.roleName)
      //       sessionStorage.setItem('selectedRole',adminRolePresent.roleName)
      //       this.router.navigate(['/userGroupSelectPage']);
      //     }else{
      //       sessionStorage.setItem('%qr',JSON.stringify(adminRolePresent));
      //       sessionStorage.setItem('%qrCopy',JSON.stringify(adminRolePresent));
      //       sessionStorage.setItem('globalCompanyList',adminRolePresent.globalCompanyMasterIdList);
      //       this.service.shareData.next();
      //       //changes for single company
      //     let tempRole = JSON.parse(sessionStorage.getItem('%qr'));
      //     tempRole.groupName=adminRolePresent.xtenantId
      //     sessionStorage.setItem('%qr',JSON.stringify(tempRole))
      //       this.service.shareCompName.next(adminRolePresent.companyName)
      //     this.service.shareRoleName.next(adminRolePresent.roleName)
      //     sessionStorage.setItem('selectedRole',adminRolePresent.roleName)
      //       this.router.navigate(['/dashboard']);
      //     }
      //   }

      //   }, (error: any) => {
      //     this.isLoader = false;
      //   })
      //   this.getDataRoleWise();
      //   this.alertService.sweetalertMasterSuccess('Login Successful', '');
      },
        (err) => {
          this.isLoader = false;
          if (err instanceof HttpErrorResponse) {
            if (err.error.status.code === '400') {
              this.alertService.sweetalertError(
                err.error.status.message,
              );
            }
          }
        });
  }
  showAssignedUserRoles(){
        // this.service.getAssignedUserRoles().subscribe(res=>{
          this.isLoader = false;
          // this.distinctRoles=res.data.results[0];
         let globalRolePresent =  this.distinctRoles.find(x=>x.type=='Global')
         let adminRolePresent =  this.distinctRoles.find(x=>x.type=='Admin')
         sessionStorage.setItem('selectedCountry','')
         sessionStorage.setItem('selectedGroup','')
         sessionStorage.setItem('selectedRole','')
         sessionStorage.setItem('selectedCompany','')
         let empData = this.distinctRoles.find(x=>x.type=='Employee')
         if(empData != undefined){
         sessionStorage.setItem('empComp',this.service.enValue(JSON.stringify(empData.xtenantId)))
         }
        if(this.distinctRoles.length==1){
        //  sessionStorage.setItem('%qr',JSON.stringify(this.distinctRoles[0]))
         sessionStorage.setItem('%qr',this.service.enObject(this.distinctRoles[0]))
         this.service.lastLoginTime.next();
        // sessionStorage.setItem('%qrCopy',JSON.stringify(this.distinctRoles[0]));
        sessionStorage.setItem('%qrCopy',this.service.enObject(this.distinctRoles[0]))
        sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(this.distinctRoles[0].userRoleId)))
        localStorage.setItem('employeeMasterId',this.user.UserDetails.employeeMasterId);
        this.service.shareData.next();
        this.router.navigate(['/dashboard']);
         }
       else if(globalRolePresent){
        if(globalRolePresent.globalCompanyMasterIdList.split(',').length>1){
          this.service.shareRoleName.next(globalRolePresent.roleName)
          sessionStorage.setItem('selectedRole',this.service.enValue(globalRolePresent.roleName))
          // sessionStorage.setItem('%qr',JSON.stringify(globalRolePresent));
          sessionStorage.setItem('%qr',this.service.enObject(globalRolePresent))
          this.service.lastLoginTime.next();
          // sessionStorage.setItem('%qrCopy',JSON.stringify(globalRolePresent));
          sessionStorage.setItem('%qrCopy',this.service.enObject(globalRolePresent))
          sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(globalRolePresent.userRoleId)))
          this.router.navigate(['/userGroupSelectPage']);
        }else{
          // sessionStorage.setItem('%qr',JSON.stringify(globalRolePresent));
          sessionStorage.setItem('%qr',this.service.enObject(globalRolePresent))
          this.service.lastLoginTime.next();
          // sessionStorage.setItem('%qrCopy',JSON.stringify(globalRolePresent));
          sessionStorage.setItem('%qrCopy',this.service.enObject(globalRolePresent))
          sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(globalRolePresent.globalCompanyMasterIdList)));
          this.service.shareData.next();
          //changes for single company
          let tempRole = this.service.deObject(sessionStorage.getItem('%qr'));
            tempRole.groupName=globalRolePresent.xtenantId
            tempRole.globalCompanyMasterId =JSON.parse(tempRole.globalCompanyMasterIdList)
            // sessionStorage.setItem('%qr',JSON.stringify(tempRole))
            sessionStorage.setItem('%qr',this.service.enObject(tempRole))
            this.service.lastLoginTime.next();
            this.service.shareCompName.next(globalRolePresent.companyName)
            this.service.shareRoleName.next(globalRolePresent.roleName)
            sessionStorage.setItem('selectedRole',this.service.enValue(globalRolePresent.roleName))
          this.router.navigate(['/dashboard']);
        }
        }else if(adminRolePresent){
          sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(adminRolePresent.userRoleId)))
          // adminRolePresent.xtenantId = adminRolePresent.loginCompanyName
          // sessionStorage.setItem('%qr',JSON.stringify(adminRolePresent));
          sessionStorage.setItem('%qr',this.service.enObject(adminRolePresent))
          this.service.lastLoginTime.next();
            // sessionStorage.setItem('%qrCopy',JSON.stringify(adminRolePresent));
            sessionStorage.setItem('%qrCopy',this.service.enObject(adminRolePresent))
          if(adminRolePresent.globalCompanyMasterIdList.split(',').length>1){
            this.service.shareRoleName.next(adminRolePresent.roleName)
            sessionStorage.setItem('selectedRole',this.service.enValue(adminRolePresent.roleName))
            this.router.navigate(['/userGroupSelectPage']);
          }else{
            // sessionStorage.setItem('%qr',JSON.stringify(adminRolePresent));
            sessionStorage.setItem('%qr',this.service.enObject(adminRolePresent))
            this.service.lastLoginTime.next();
            // sessionStorage.setItem('%qrCopy',JSON.stringify(adminRolePresent));
            sessionStorage.setItem('%qrCopy',this.service.enObject(adminRolePresent))
            sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(adminRolePresent.globalCompanyMasterIdList)));
            this.service.shareData.next();
            //changes for single company
          let tempRole = this.service.deObject(sessionStorage.getItem('%qr'));
          tempRole.groupName=adminRolePresent.xtenantId
          // sessionStorage.setItem('%qr',JSON.stringify(tempRole))
          sessionStorage.setItem('%qr',this.service.enObject(tempRole))
          this.service.lastLoginTime.next();
            this.service.shareCompName.next(adminRolePresent.companyName)
          this.service.shareRoleName.next(adminRolePresent.roleName)
          sessionStorage.setItem('selectedRole',this.service.enValue(adminRolePresent.roleName))
            this.router.navigate(['/dashboard']);
          }
        }

  //       }, (error: any) => {
  //         this.isLoader = false;
  //       })
        this.getDataRoleWise();
  //       this.alertService.sweetalertMasterSuccess('Login Successful', '');
  // this.isLoader = false
    this.alertService.sweetalertMasterSuccess('Login Successful', '');
}

 showLastLoginRoles(lastLoginType){
          // this.service.getAssignedUserRoles().subscribe(res=>{
            this.isLoader = false;
            // this.distinctRoles=res.data.results[0];
           let globalRolePresent =  this.distinctRoles.find(x=>x.type=='Global')
           let adminRolePresent =  this.distinctRoles.find(x=>x.type=='Admin')
           let employeeRolePresent = this.distinctRoles.find(x=>x.type=='Employee')
           sessionStorage.setItem('selectedCountry','')
           sessionStorage.setItem('selectedGroup','')
           sessionStorage.setItem('selectedRole','')
           sessionStorage.setItem('selectedCompany','')
           let empData = this.distinctRoles.find(x=>x.type=='Employee')
           if(empData != undefined){
           sessionStorage.setItem('empComp',this.service.enValue(JSON.stringify(empData.xtenantId)))
           }
          if(lastLoginType == 'Employee'){
           sessionStorage.setItem('%qr',this.service.enObject(employeeRolePresent))
           this.service.shareRoleName.next(employeeRolePresent.roleName)
            // sessionStorage.setItem('selectedRole',employeeRolePresent.roleName)
           this.service.lastLoginTime.next();
          // sessionStorage.setItem('%qrCopy',JSON.stringify(employeeRolePresent));
          sessionStorage.setItem('%qrCopy',this.service.enObject(employeeRolePresent))
          sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(employeeRolePresent.userRoleId)))
          localStorage.setItem('employeeMasterId',this.user.UserDetails.employeeMasterId);
          this.service.shareData.next();
          this.router.navigate(['/employee-master/employee-dashboard']);
           }
         else if(lastLoginType == 'Global'){
          if(globalRolePresent.globalCompanyMasterIdList.split(',').length>1){
            this.service.shareRoleName.next(globalRolePresent.roleName)
            sessionStorage.setItem('selectedRole',this.service.enValue(globalRolePresent.roleName))
            // sessionStorage.setItem('%qr',JSON.stringify(globalRolePresent));
            sessionStorage.setItem('%qr',this.service.enObject(globalRolePresent))
            this.service.lastLoginTime.next();
            // sessionStorage.setItem('%qrCopy',JSON.stringify(globalRolePresent));
            sessionStorage.setItem('%qrCopy',this.service.enObject(globalRolePresent))
            sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(globalRolePresent.userRoleId)))
            this.router.navigate(['/userGroupSelectPage']);
          }else{
            // sessionStorage.setItem('%qr',JSON.stringify(globalRolePresent));
            sessionStorage.setItem('%qr',this.service.enObject(globalRolePresent))
            this.service.lastLoginTime.next();
            // sessionStorage.setItem('%qrCopy',JSON.stringify(globalRolePresent));
            sessionStorage.setItem('%qrCopy',this.service.enObject(globalRolePresent))
            sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(globalRolePresent.globalCompanyMasterIdList)));
            this.service.shareData.next();
            //changes for single company
            let tempRole = this.service.deObject(sessionStorage.getItem('%qr'));
              tempRole.groupName=globalRolePresent.xtenantId
              tempRole.globalCompanyMasterId =JSON.parse(tempRole.globalCompanyMasterIdList)
              // sessionStorage.setItem('%qr',JSON.stringify(tempRole))
              sessionStorage.setItem('%qr',this.service.enObject(tempRole))
              this.service.lastLoginTime.next();
              this.service.shareCompName.next(globalRolePresent.companyName)
              this.service.shareRoleName.next(globalRolePresent.roleName)
              sessionStorage.setItem('selectedRole',this.service.enValue(globalRolePresent.roleName))
            this.router.navigate(['/dashboard']);
          }
          }else if(lastLoginType == 'Admin'){
            sessionStorage.setItem('%rid',this.service.enValue(JSON.stringify(adminRolePresent.userRoleId)))
            // adminRolePresent.xtenantId = adminRolePresent.loginCompanyName
            // sessionStorage.setItem('%qr',JSON.stringify(adminRolePresent));
            sessionStorage.setItem('%qr',this.service.enObject(adminRolePresent))
            this.service.lastLoginTime.next();
              // sessionStorage.setItem('%qrCopy',JSON.stringify(adminRolePresent));
              sessionStorage.setItem('%qrCopy',this.service.enObject(adminRolePresent))
            if(adminRolePresent.globalCompanyMasterIdList.split(',').length>1){
              this.service.shareRoleName.next(adminRolePresent.roleName)
              sessionStorage.setItem('selectedRole',this.service.enValue(adminRolePresent.roleName))
              adminRolePresent.globalCompanyMasterId1 = adminRolePresent.globalCompanyMasterId
              this.router.navigate(['/userGroupSelectPage']);
            }else{
              adminRolePresent.globalCompanyMasterId1 = adminRolePresent.globalCompanyMasterId
              // sessionStorage.setItem('%qr',JSON.stringify(adminRolePresent));
              sessionStorage.setItem('%qr',this.service.enObject(adminRolePresent))
              this.service.lastLoginTime.next();
              // sessionStorage.setItem('%qrCopy',JSON.stringify(adminRolePresent));
              sessionStorage.setItem('%qrCopy',this.service.enObject(adminRolePresent))
              // sessionStorage.setItem('globalCompanyList',adminRolePresent.globalCompanyMasterIdList);
              sessionStorage.setItem('globalCompanyList',this.service.enValue(JSON.stringify(adminRolePresent.globalCompanyMasterId)))
              this.service.shareData.next();
              //changes for single company
            let tempRole = this.service.deObject(sessionStorage.getItem('%qr'));
            tempRole.groupName=adminRolePresent.xtenantId
            // sessionStorage.setItem('%qr',JSON.stringify(tempRole))
            sessionStorage.setItem('%qr',this.service.enObject(tempRole))
            this.service.lastLoginTime.next();
              this.service.shareCompName.next(adminRolePresent.companyName)
            this.service.shareRoleName.next(adminRolePresent.roleName)
            sessionStorage.setItem('selectedRole',this.service.enValue(adminRolePresent.roleName))
              this.router.navigate(['/dashboard']);
            }
          }
          this.getDataRoleWise();   
      this.alertService.sweetalertMasterSuccess('Login Successful', '');
 }

  resendOTP() {
    this.timeLeft = 600;
    this.isResendOTP = false
    const data = {
      emailId: this.email,
      password: this.password,
    };
    this.service.postLogin(data)
      .subscribe((res) => {
      }
      );
  }

  // change locale/language at runtime
  updateLocale(locale) {
    localStorage.setItem('selectedLanguage', locale);
    let ind = this.locales.findIndex(e=> e.value == locale)
    if(ind != -1){
      sessionStorage.setItem('selectedLanguage', this.locales[ind].label);
    }
    if (this.locales.some((l) => l.value === locale)) {
      this.locale = locale;
    }
    const lang = locale.substring(0, 2);
    this.translocoService.setActiveLang(lang);
  }

  getDataRoleWise(){
    this.service.getDataRoleWise().subscribe(res =>{
        this.roleWiseData = res.data.results;
    })
  }
  extralargepopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-xl' })
    );
  }

  hideConfirmAccountNo( accountNumber ) {
    this.accType= 'number';
       setTimeout( () => {
         this.accType = 'password';
       }, 200)
   }

   onClickEye(){
    this.isShowPassword = !this.isShowPassword;
   }
}
