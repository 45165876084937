
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeGb from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule } from '@ngneat/transloco';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { translocoLoader } from './core/strategies/transloco.loader';
import { BnNgIdleService } from 'bn-ng-idle';


registerLocaleData(localeFr, 'fr');
registerLocaleData(localeGb, 'en-GB');

import { AuthGuard } from './modules/auth/auth.guard';
import { TokenInterceptorService } from './modules/auth/token-interceptor/token-interceptor.service';
import { ShortenStringPipe } from './core/utility/pipes/shorten-string.pipe';
import { ExcelserviceService } from './core/services/excelservice.service';
import { SharedlayoutModule } from './modules/sharedlayout/sharedlayout.module';
import { CheckUserTimeDirective } from './core/utility/directives/check-user-time.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { AuthModule } from './modules/auth/auth.module';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    AppComponent,
    // ProfileComponent,
    ShortenStringPipe,
    CheckUserTimeDirective,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AuthModule,
    ModalModule.forRoot(),
    SharedlayoutModule,
    ToastrModule.forRoot({
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BrowserAnimationsModule,
    TranslocoModule,
    AppRoutingModule,
  ],
  providers: [BsDatepickerModule,
    BnNgIdleService,
    ExcelserviceService,
    AuthGuard,
    BsModalService,
    translocoLoader, {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: [{ id: 'en', label: 'English' }, { id: 'fr', label: 'French' }, { id: 'hi', label: 'Hindi' }, { id: 'si', label: 'Sinhala' }, { id: 'ba', label: 'Bangali ' }, { id: 'ma', label: 'Mandarin' },{ id: 'mr', label: 'Marathi' },{ id: 'ge', label: 'German' },{ id: 'ka', label: 'Kannada' },{ id: 'ja', label: 'Japanies' }],
        listenToLangChange: true,
        reRenderOnLangChange: true,
        defaultLang: 'en',
        fallbackLang: 'fr',

        prodMode: false,
      } as TranslocoConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    ShortenStringPipe,
  ],
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA
  // ],


  bootstrap: [AppComponent]
})
export class AppModule { }
