
import { Translation, TRANSLOCO_LOADER, TranslocoLoader } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

// import * as en from './../../../assets/i18n/en.json';
// import * as fr from './../../../assets/i18n/fr.json';

var en = require('./../../../assets/i18n/english.json');
var fr = require('./../../../assets/i18n/french.json');
var hi = require('./../../../assets/i18n/hindi.json');
var ba = require('./../../../assets/i18n/bangali.json');
var si = require('./../../../assets/i18n/sinhala.json');
var ma = require('./../../../assets/i18n/mandarin.json');

// "use strict";
// const express = require("express");
// const config = require("./../../assets/i18n/fr.json");
// const app = express();

// app.listen(config.server.nodePort, () => {
//   console.log(`Listening on port ${config.server.nodePort} ...`);
// });
/**
 * DO NOT USE THIS IS LOADER IN YOUR APP
 * 
 * This is a special loader for stackblitz.
 * In a normal app, use the loader generated by the transloco schematic.
 */
@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  
  getTranslation(langPath: string) {
    
    if (langPath === 'en') {
      return of(en);
    } 
    if(langPath === 'fr') {
      return of(fr);
    }
    if(langPath === 'hi') {
      return of(hi);
    }
    if(langPath === 'ba') {
      return of(ba);
    }
    if(langPath === 'si') {
      return of(si);
    }
    if(langPath === 'ma') {
      return of(ma);
    }
  }
}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
