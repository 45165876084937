<div class="section-body mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header mb-0 font-bold color-purple">
              Document
            </div>
            <div class="card-body">
              <div class="tabview-contents">
                <div class="row">
                  <div class="col-md-3 mt-3" *ngFor="let data of extraDetails">
                      <label class="form-label">{{data.label}}
                      </label>
                      <div class="input-group">
                          <input type="text" class="form-control" [value]="data.value" placeholder="" disabled>
                      </div>
                  </div>
              </div>
                <div class="row mt-25">
                  <div class="col-md-1">
                    <button type="button" class="btn active Next_Previous_Btn" tooltip="Previous Document"
                      (click)="prev()" [disabled]="selectDocIndex==0" *ngIf="documetLst.length!=1">
                      <i class="fa fa-angle-left text-white font-30 p-10"></i>
                    </button>
                  </div>
                  <div class="col-md-10">
                    <iframe class="iframe-css-scroll" [src]="blobURI" provider="google"></iframe>
                  </div>
                  <div class="col-md-1">
                    <button type="button" class="btn active Next_Previous_Btn" tooltip="Next Document"
                      (click)="next()" [disabled]="selectDocIndex==documetLst.length-1" *ngIf="documetLst.length!=1">
                      <i class="fa fa-angle-right text-white font-30 p-10"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>