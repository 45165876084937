<div class="section-body mt-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="col-md-12" *ngIf="showHelpDoc">
                        <div class="float-right"><span (click)="openPopup(document)">
                                <i class="fa-solid fa-file-export text-link" tooltip="Help Document"></i>
                                <span class="ml-1 text-link ">User Guide</span>
                            </span>
                        </div>
                    </div>
                    <div class="page-loader-wrapper" *ngIf="isLoader">
                        <div class="loader-1">
                            <div class="loader-outter"></div>
                            <div class="loader-inner"></div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- <div class="col-md-12">
                            <div class="float-right"><span (click)="openPopup(document)">
                                    <span class="ml-1 text-link ">User Guide</span>
                                </span>
                            </div>
                        </div> -->
                        <div class="" *ngIf="!visibleButtons">
                            <div class="row mt-25">
                                <div class="col-md-12">
                                    <div class="table-responsive Custom_Table fixed-header">
                                        <!-- <p-table #dt1 [value]="EmployeeSummary" [paginator]="true" [rows]="size"
                                            [customSort]="true"
                                            [rowsPerPageOptions]="[10,25,50,100,200]"
                                             [totalRecords]="totalRecords"
                                            [paginator]="true"
                                            [lazy]="true" 
                                            (onLazyLoad)="paginate($event)"
                                            [showCurrentPageReport]=true
                                            [currentPageReportTemplate]="'{currentPage} of {totalPages}'"
                                            [globalFilterFields]="['employeeCode','fullName','officialMobileNumber','joiningDate','lastWorkingDate','status','establishment','department','grade','designation1']"> -->
                                        <p-table #dt1 [value]="EmployeeSummary" [paginator]="true" [rows]="10"
                                            [showCurrentPageReport]="true"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                            [rowsPerPageOptions]="[10,25,50,100,200]"
                                            [globalFilterFields]="['employeeCode','fullName','joiningDate','lastWorkingDate','status','establishment','department','grade','designation1','payrollAreaCode','approval']">
                                            <ng-template pTemplate="caption">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <button type="button"
                                                            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2 "
                                                            (click)="addNewEmployee()"
                                                            [ngClass]="{'disabled-btn-bg-green' : selectedEmployeeData.length !=0 }"
                                                            [disabled]="selectedEmployeeData.length !=0"
                                                            [routerLink]="['/employee-master/personal-information']"><b><i
                                                                    class="fa fa-plus"></i></b>
                                                            New Joinee
                                                        </button>
                                                        <button type="button"
                                                            [ngClass]="{'disabled-btn-bg-green' : selectedEmployeeData.length != 1 || disableRejoin}"
                                                            [disabled]="selectedEmployeeData.length !=1  ||disableRejoin"
                                                            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left mr-2 "
                                                            (click)="onClickReJoine()"
                                                            [routerLink]="['/employee-master/employment-information/re-joining-information']"><b><i
                                                                    class="fa fa-plus"></i></b>
                                                            Rejoinee </button>
                                                        <button type="button"
                                                            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2 "
                                                            (click)="createLogin(selectedEmployeeData,'Send')"
                                                            [disabled]="selectedEmployeeData.length ==0 || createLoginDisable"
                                                            [ngClass]="{'disabled-btn-bg-green' : selectedEmployeeData.length == 0 || createLoginDisable}"><b><i
                                                                    class="fa fa-plus"></i></b>
                                                            Create Login</button>
                                                        <!-- <button type="button" tooltip="Create Login"
                                                            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2 "><b><i
                                                                    class="fa fa-plus"></i></b>
                                                            Create Multiple Login</button> -->
                                                        <button type="button"
                                                            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2 "
                                                            (click)="createLogin(selectedEmployeeData,'Resend')"
                                                            [disabled]="selectedEmployeeData.length ==0 || createLoginDisable"
                                                            [ngClass]="{'disabled-btn-bg-green' : selectedEmployeeData.length == 0 || createLoginDisable}"><b><i
                                                                    class="fa fa-plus"></i></b>
                                                            Recreate Login</button>
                                                        <!-- <button type="button" tooltip="Create Login"
                                                            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2 "><b><i
                                                                    class="fa fa-plus"></i></b>Recreate Multiple
                                                            Login</button> -->
                                                        <!-- <div class="rup mt-2 p-10"> -->
                                                        <button type="button"
                                                            class="custom_button custom_btn btn-bg-green btn-lg btn-labeled btn-labeled-left ripple  mr-2 "
                                                            tooltip="Click to Select Employees"
                                                            (click)=" copySelectDeselectPopUp(copyDeselect,false)">
                                                            <b> <i class="fa fa-external-link-square"></i></b>Select
                                                            Employees
                                                        </button>
                                                        <!-- </div> -->
                                                        <!-- <div class="rup mt-2 p-10"> -->
                                                        <button type="button"
                                                            class="custom_button custom_btn bg-btn-red btn-lg btn-labeled btn-labeled-left ripple  mr-2 "
                                                            tooltip="Click to Deselect Employees"
                                                            (click)="copySelectDeselectPopUp(copyDeselect,true)">
                                                            <b><i class="fa fa-external-link-square"></i></b>Deselect
                                                            Employees
                                                        </button>
                                                        <!-- </div> -->
                                                    </div>
                                                    <div class="col-md-12 mt-3">
                                                        <!-- <div class="float-left">
                                                            <button type="button" tooltip="Create Login"
                                                                class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2 "><b><i
                                                                        class="fa fa-plus"></i></b>
                                                                Resend Welcome Email
                                                            </button>
                                                            <button type="button" tooltip="Create Login"
                                                                class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left  mr-2 "><b><i
                                                                        class="fa fa-plus"></i></b>
                                                                Resend Multiple Employee Welcome Email 
                                                            </button>
                                                        </div> -->
                                                        <div class="float-right">
                                                            <div class="d-flex">
                                                                <span class="p-input-icon-left p-ml-auto ">
                                                                    <i class="pi pi-search search-css-new"></i>
                                                                    <input pInputText type="text" class="form-control"
                                                                        (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                                                        placeholder="Search " />
                                                                </span>
                                                                <i (click)="exportAsXLSX()"
                                                                    class="fa fa-file-excel-o excel-icon-css mr-2"></i>
                                                                <select class="wd-18 form-control custom-select mr-2"
                                                                    (change)="filterApply($event.target.value)">
                                                                    <option value=''>Select Filter</option>
                                                                    <option value='{{data.filterMasterId}}'
                                                                        *ngFor="let data of allFilterSummary">
                                                                        {{data.name}}
                                                                    </option>
                                                                </select>
                                                                <i (click)="showFilterUI()"
                                                                    class="fa fa-filter cursor-pointer text-purple fa-2x"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="header">
                                                <tr class="sticky">
                                                    <th>
                                                        <p-tableHeaderCheckbox (click)="getSelectedEmployee1(c.checked)"
                                                            #c></p-tableHeaderCheckbox>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Emp. Code
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="employeeCode"
                                                                    pSortableColumn="employeeCode">
                                                                </p-sortIcon>
                                                                <p-columnFilter field="employeeCode" display="menu"
                                                                    class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Emp. Name
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="fullName" pSortableColumn="fullName">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="fullName"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Company Name
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="companyName"
                                                                    pSortableColumn="companyName">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="companyName"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <!-- <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Mobile Number
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="officialMobileNumber"
                                                                    pSortableColumn="officialMobileNumber">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="officialMobileNumber"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th> -->
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Joining Date
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="joiningDate"
                                                                    pSortableColumn="joiningDate">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="date" field="joiningDate"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>

                                                    <!-- <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            ReJoining Date
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="reJoiningDate"
                                                                    pSortableColumn="reJoiningDate">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="reJoiningDate"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th> -->
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Leaving Date
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="lname" pSortableColumn="lname">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="date" field="lname" display="menu"
                                                                    class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Status
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="Status" pSortableColumn="Status">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="Status"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Payroll Area
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="payrollAreaCode"
                                                                    pSortableColumn="payrollAreaCode">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="payrollAreaCode"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Establishment
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="etype" pSortableColumn="etype">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="etype" display="menu"
                                                                    class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Department
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="department"
                                                                    pSortableColumn="department">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="department"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Grade
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="grade" pSortableColumn="grade">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="PSIdDetails"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Designation
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="designation"
                                                                    pSortableColumn="designation">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="designation"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="p-d-flex justify-content-center p-ai-center">
                                                            Approval Status
                                                            <div class="Sort_Filter_Position">
                                                                <p-sortIcon field="approval" pSortableColumn="approval">
                                                                </p-sortIcon>
                                                                <p-columnFilter type="text" field="approval"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>Action</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-user1>
                                                <tr class="p-selectable-row">
                                                    <td>
                                                        <!-- <p-tableCheckbox (click)="getSelectedEmployee(user1,e.checked)"
                                                            [checked]="user1.checked==true" #e [value]="user1">
                                                        </p-tableCheckbox>
                                                        <div>Checkbox State: {{ user1.checked }}</div> -->
                                                        <input type="checkbox" [checked]="user1.checked==true" #e
                                                            [value]="user1.checked"
                                                            (click)="getSelectedEmployee(user1,e.checked)">
                                                    </td>
                                                    <td class="text-left" tooltip="{{user1.employeeCode}}">
                                                        {{user1.employeeCode}}</td>
                                                    <td class="text-left" tooltip="{{user1.fullName}}">
                                                        {{user1.fullName}}</td>
                                                    <td class="text-left" tooltip="{{user1.companyName}}">
                                                        {{user1.companyName}}</td>
                                                    <!-- <td class="text-left" tooltip="{{user1.officialMobileNumber}}">
                                                        {{user1.officialMobileNumber}}</td> -->
                                                    <td class="text-center" tooltip="{{user1.joiningDate }}">
                                                        {{user1.joiningDate}}</td>
                                                    <!-- <td class="text-center" tooltip="{{user1.reJoiningDate }}">
                                                        {{user1.reJoiningDate}}</td> -->
                                                    <td class="text-center" tooltip="{{user1.lastWorkingDate  }}">
                                                        {{user1.lastWorkingDate }} </td>
                                                    <td class="text-left" tooltip="{{user1.status}}"> <span>
                                                            <span *ngIf="user1.status == 'Probation'">
                                                                <span class="badge badge-warning">
                                                                    {{user1.status==null?"":user1.status}}
                                                                </span>
                                                            </span>
                                                            <span *ngIf="user1.status== 'Confirmed'">
                                                                <span class="badge badge-success">
                                                                    {{user1.status==null?"":user1.status}}
                                                                </span>
                                                            </span>
                                                            <span *ngIf="user1.status == 'Resigned'">
                                                                <span class="badge badge-info">
                                                                    {{user1.status==null?"":user1.status}}
                                                                </span>
                                                            </span>
                                                            <span *ngIf="user1.status == 'Inactive'">
                                                                <span class="badge badge-secondary">
                                                                    {{user1.status==null?"":user1.status}}
                                                                </span>
                                                            </span>
                                                            <span *ngIf="user1.status == 'Exited'">
                                                                <span class="badge badge-danger">
                                                                    {{user1.status==null?"":user1.status}}
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td class="text-left" tooltip="{{user1.payrollAreaCode}}">
                                                        {{user1.payrollAreaCode}}
                                                    </td>
                                                    <td class="text-left" tooltip="{{user1.establishment}}">
                                                        {{user1.establishment}}</td>
                                                    <td class="text-left" tooltip="{{user1.department}}">
                                                        {{user1.department}}</td>
                                                    <td class="text-left" tooltip="{{user1.grade}}">{{user1.grade}}</td>
                                                    <td class="text-left" tooltip="{{user1.designation1}}">
                                                        {{user1.designation1}}</td>
                                                    <td class="text-left" tooltip="{{user1.approval}}">
                                                        <span class="badge badge-success">
                                                            {{user1.approval}}
                                                        </span>
                                                    </td>
                                                    <td class="text-center">
                                                        <!-- [routerLink]="['/employee-master/personal-information']" -->
                                                        <div class="d-flex">
                                                            <i (click)="editEmployee(user1);navigateToForm()"
                                                                tooltip="Edit"
                                                                class=" fa fa-edit edit-icon-css mr-2 "></i>
                                                            <!-- [routerLink]="['/employee-master/personal-information']" -->
                                                            <i (click)="viewEmployee(user1);navigateToForm()"
                                                                tooltip="View" class="fa fa-eye eye-icon-css mr-2"></i>
                                                            <!-- <i class="fa fa-check-square-o check-icon-css mr-2"></i> -->
                                                            <i class="fa-solid fa-user text-blue font-14 cursor-pointer"
                                                                tooltip="Click here to Employee Login"
                                                                *ngIf="isPermissable"
                                                                (click)="showPopupModal(template12,user1.employeeMasterId,user1.groupCompanyId)"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #emplist>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel">Employee List</h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row  ">
                    <div class="col-md-12">
                        <textarea rows="3" cols="60"> </textarea>
                    </div>
                </div>
                <div class="row mt-15">
                    <div class="col-md-12">
                        <p-table #dt2 [value]="EmployeeSummary" [paginator]="true" [rows]="10"
                            [showCurrentPageReport]="true" [customSort]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [rowsPerPageOptions]="[10,25,50,200]"
                            [globalFilterFields]="['empcode','empName','Group','Frequency','Type','proofsubmission','PSIdDetails','SubmissionDate','Status']">
                            <ng-template pTemplate="caption">
                                <div class="row">
                                    <div class="col-md-6 mt-10 d-flex ">
                                        <label class="form-label mr-5">Total Records (10) </label>
                                        <label class="form-label">Selected Records (2) </label>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="float-right">
                                            <i class="fa fa-file-excel-o text-dark-green fa-2x mr-2"
                                                (click)="exportAsXLSX()"></i>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center"> Emp.Code
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="empcode" pSortableColumn="empcode"></p-sortIcon>
                                                <p-columnFilter field="empcode" display="menu" class="p-ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center">Emp Name
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="empName" pSortableColumn="empName"></p-sortIcon>
                                                <p-columnFilter type="text" field="empName" display="menu"
                                                    class="p-ml-auto"> </p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center"> Company Name
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="PSIdDetails" pSortableColumn="PSIdDetails">
                                                </p-sortIcon>
                                                <p-columnFilter type="text" field="PSIdDetails" display="menu"
                                                    class="p-ml-auto"> </p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center"> Service
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="SubmissionDate" pSortableColumn="SubmissionDate">
                                                </p-sortIcon>
                                                <p-columnFilter type="text" field="SubmissionDate" display="menu"
                                                    class="p-ml-auto"> </p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center">Area
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="proofsubmission"
                                                    pSortableColumn="proofsubmission"></p-sortIcon>
                                                <p-columnFilter type="text" field="name" display="menu"
                                                    class="p-ml-auto"> </p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center"> Establishment
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="Type" pSortableColumn="Type"></p-sortIcon>
                                                <p-columnFilter type="text" field="Type" display="menu"
                                                    class="p-ml-auto"> </p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center"> Department
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="Type" pSortableColumn="Type"></p-sortIcon>
                                                <p-columnFilter type="text" field="Type" display="menu"
                                                    class="p-ml-auto"></p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center">Grade
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="ItSection" pSortableColumn="ItSection">
                                                </p-sortIcon>
                                                <p-columnFilter type="text" field="ItSection" display="menu"
                                                    class="p-ml-auto"> </p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="p-d-flex justify-content-center p-ai-center"> Designation
                                            <div class="Sort_Filter_Position">
                                                <p-sortIcon field="ItSection" pSortableColumn="Group"></p-sortIcon>
                                                <p-columnFilter type="text" field="Group" display="menu"
                                                    class="p-ml-auto"></p-columnFilter>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-customer>
                                <tr class="p-selectable-row">
                                    <td>{{ customer.empcode}}</td>
                                    <td>{{ customer.empName}}</td>
                                    <td> {{customer.companyname}}</td>
                                    <td> {{customer.service}}</td>
                                    <td> {{customer.area}}</td>
                                    <td> {{ customer.establishment}}</td>
                                    <td> {{ customer.department}}</td>
                                    <td> {{ customer.grade}}</td>
                                    <td> {{ customer.designation}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
        <button type="submit" class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple"
            tooltip="Save"><b><i class="fa fa-arrow-circle-o-right "></i></b> Proceed</button>
    </div>
</ng-template>
<ng-template #template>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel">Add-Query</h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="custom-controls-stacked">
                                <span class="d-flex"> <label class="form-label mr-55">On
                                        Behalf of Employee's</label>
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="BehalfOfEmployee"
                                            name="querydata1" (click)="onBehalf('yes')">
                                        <span class="custom-control-label">Yes</span>
                                    </label>
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="BehalfOfEmployee"
                                            name="querydata1" (click)="onBehalf('no')">
                                        <span class="custom-control-label">No</span>
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-25">
                            <div class="custom-controls-stacked">
                                <span class="d-flex"> <label class="form-label mr-15">Same
                                        Content For All Employee</label>
                                    <label class="custom-control custom-radio custom-control-inline ">
                                        <input type="radio" class="custom-control-input" id="sameContent"
                                            name="querydata2">
                                        <span class="custom-control-label">Yes</span>
                                    </label>
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="sameContent"
                                            name="querydata2">
                                        <span class="custom-control-label">No</span>
                                    </label></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" tooltip="Proceed" (click)="modalRef.hide(); " [routerLink]="['/admin-query-generation']"
            [disabled]="onBehalfValue == ''  &&  selectedEmployeeData.length > 1"
            [ngClass]="{'disabled-btn-bg-green' :onBehalfValue == ''  &&  selectedEmployeeData.length > 1 }"
            class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left float-right"><b><i
                    class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></b> Proceed</button>
        <button type="button" tooltip="Cancel" (click)="modalRef.hide()" data-dismiss="modal" aria-label="Close"
            class="custom_button custom_btn bg-btn-red btn-labeled btn-labeled-left"><b><i class="fa fa-times"></i></b>
            Cancel</button>
    </div>
</ng-template>
<ng-template #template12>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel"></h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <label class="form-label">Do you want to login on behalf of Employee ?
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onEmpLogin();modalRef.hide()">Yes</button>
        <button type="button" class="btn btn-secondary bg-btn-red" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>
<!-- <ng-template #document>
    <app-help-document-popup [modalRef1]="modalRef" [type]="type"></app-help-document-popup>
</ng-template> -->
<app-filters *ngIf="isDisplayFilter " (filterEvent)="filterEventHander($event)"
    (AllFiltersEvent)="getAllFilterSummary()"></app-filters>
<ng-template #document>
    <app-help-document-popup [modalRef1]="modalRef" [type]="type"></app-help-document-popup>
</ng-template>
<!-- copyDeselect -->
<ng-template #copyDeselect>
    <div class="modal-header">
        <h5 class="modal-title" id="UploadModalLabel">{{isSelection===true?"Deselect":"Select"}} Employee List</h5>
        <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card">
            <div class="card-body">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <textarea id="editor" cols="50" rows="5" [(ngModel)]="pastedData"
                            placeholder="Paste Employee Code here..."></textarea>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
        </div>
        <div class="row mt-15">
            <div class="col-12">
                <div class="float-right mb-15">
                    <button type="submit"
                        class="custom_button custom_btn btn-bg-green btn-labeled btn-labeled-left ripple"
                        (click)="deselectFromPaste()" [disabled]="!pastedData">
                        <b> <i class="fa fa-arrow-circle-right"></i></b> Proceed</button>
                    &nbsp;&nbsp;
                    <button type="button" class="custom_button custom_btn bg-teal-400 btn-labeled btn-labeled-left"
                        (click)="modalRef.hide()">
                        <b> <i class="fa fa-times"></i></b> Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- copyDeselect -->