import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { delay, map } from 'rxjs/operators';
import { ExcelserviceService } from 'src/app/core/services/excelservice.service';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { LmsFormInputService } from 'src/app/modules/lms/lms-forms-input/lms-form-input.service';
export interface User1 {
  empcode,
  empname,
  payrollarea,
  lmsarea,
  companyname,
  emptype,
  establishment,
  department,
  grade,
  designation,
}
@Component({
  selector: 'app-lms-forms-input',
  templateUrl: './lms-forms-input.component.html',
  styleUrls: ['./lms-forms-input.component.scss']
})
export class LmsFormsInputComponent implements OnInit {
  users1: User1[];
  public modalRef: BsModalRef;
  allEmpList: Array<any> = [];
 
  selectedEmpList: Array<any> = [];
  getData:any;
  excelData: any[];
  header: any[];
isLoader:boolean=false;

  displayedColumns: any[] = [];
  employeeList: any[] = [];
  selectedEmpLength: any;
  curreEmpDet: any=[];

  constructor(private modalService: BsModalService,private lmsInputFormService:LmsFormInputService,private authService: AuthService,private excelservice: ExcelserviceService, private alertService: AlertServiceService,) {
    sessionStorage.removeItem('lmsEmp')
   }


  ngOnInit(): void {
    this.getData = JSON.parse(this.authService.deValue(sessionStorage.getItem('globalCompanyList')))
    
    this.getAllEmployeeList();
    
    this.users1 = [
      { empcode: '3203', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3204', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3205', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3206', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3207', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3208', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3209', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3210', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3211', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
      { empcode: '3212', empname: 'ABC DEF', payrollarea: 'PA - Worker', lmsarea: 'LA - Worker', companyname: 'Paysquare', emptype: 'Worker', establishment: 'Thane', department: 'Production', grade: 'GA1', designation: 'Production' },
    ];
  }
  employeeListCopyPaste(event, pasteEmployee: TemplateRef<any>) {
    if (event.checked) {
      this.modalRef = this.modalService.show(
        pasteEmployee,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    }
  }

  getAllEmployeeList() {
   
    let data
    data = this.authService.getprivileges();
    let id = data.UserDetails.employeeMasterId;
  
    this.isLoader=true
    const obj = {
      "globalCompanyMasterIdList":this.getData
        }
 
      this.lmsInputFormService.newMainSummary(obj).pipe(delay(50)).subscribe((res: any) => {
      this.allEmpList = res.data.results[0];
      // this.allEmpList.forEach(el=>{
      //   let obj ={
      //     "employeeMasterId":  el.employeeMasterId
      //   }

      //   this.lmsInputFormService.getEmp(obj).pipe(map(value => value.data.results[0])).subscribe((success: any) => {
      //     this.curreEmpDet = success;
      //   })
      //   el.lmsAreaCode=this.curreEmpDet.lmsCode
      // })
      this.isLoader=false
   
      for(let i=0;i<this.allEmpList.length;i++){
        this.allEmpList[i].isChecked = false
      
      }
    })
  
  }
  selectSingleEmp(check,id,i){

let ind1 =this.allEmpList.findIndex(x=>x.employeeMasterId == id)
if(ind1 != -1){
    if(check == true){
      this.allEmpList[ind1].isChecked = true
    this.selectedEmpList.push({'empId' : id,'empCode':this.allEmpList[ind1].employeeCode,'empName':this.allEmpList[ind1].fullName,'lhgId':this.allEmpList[ind1].lmsLeaveSchemaId})
    sessionStorage.setItem('lmsEmp',this.authService.enValue(JSON.stringify(this.selectedEmpList)))
    }else{
      this.allEmpList[ind1].isChecked = false
      let ind = this.selectedEmpList.findIndex(e=>e.empId == id)
      if(ind != -1){
        this.selectedEmpList.splice(ind,1)
        // sessionStorage.setItem('lmsEmp',JSON.stringify(this.selectedEmpList))
        sessionStorage.setItem('lmsEmp',this.authService.enValue(JSON.stringify(this.selectedEmpList)))
      }
    }
  }
  }

   getManualAppEmp(){
  //  let tempdata:any = []
 
  //  sessionStorage.setItem('lmsEmp',JSON.stringify(this.selectedEmpList))
  // //  tempdata = JSON.parse(sessionStorage.getItemItem('lmsEmp'))
    
  //   this.lmsInputFormService.manualAppEmpList.next(this.selectedEmpList)

  }

  getArManualAppEmp(){
    // this.lmsInputFormService.arManualAppEmpList.next(this.selectedEmpList)
  }
  getLeaveAdjustment(){
    // this.lmsInputFormService.setEmp(this.selectedEmpList)
    // this.lmsInputFormService.leaveAdjustmentList.next(this.selectedEmpList)
  }
  getEncashment(){
    // this.lmsInputFormService.selectedEmpEncashmentList.next(this.selectedEmpList)
  }
  getRoaster(){
    this.lmsInputFormService.selectedRoasterList.next(this.selectedEmpList)
  }
  exportToExcel(){
    this.excelData = [];
    this.header = []
    this.header =["Emp. Code", "Emp. Name","Payroll Area","Company Name","Emp. Type", "Establishment", "Department","Grade","Designation"];
    this.excelData=[];
    let rowIndex=1;
    if(this.allEmpList.length>0){
      this.allEmpList.forEach(element => {
      let obj = {
        // "Sr. No.":rowIndex++,
        // "Application No.":element?.applicationNo,
        // "Application Date":this.datepipe.transform(element.applicationDate,'dd-MMM-yyyy'),
        "Emp. Code":element.employeeCode,
        "Emp. Name":element.fullName,
        "Payroll Area":element.payrollAreaCode,
      //  "LMS Area":element.,
       "Company Name":element.companyName,
       "Emp. Type":element.employeeType,
       "Establishment":element.establishment,
        "Department":element.department,
        "Grade":element.grade,
        "Designation":element.designation1,
      }
      this.excelData.push(obj)
    });
  }
    this.excelservice.exportAsExcelFile(this.excelData, 'Input Form','Input Form',this.header);
  }


  employeeListPasteData(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let row_data = pastedText.split('\n');
    this.displayedColumns = ["EmpCode"]
    this.employeeList = [];
    for (let i = 0; i < row_data.length; i++) {
      let data = row_data[i].replace('\r', '');
      this.employeeList.push(data)
    }
    let lastIndex = this.employeeList.length - 1
    this.employeeList.splice(lastIndex, 1)
    this.selectedEmpList = []
    this.allEmpList.forEach(user => {
      this.employeeList.forEach(element => {
        if (user.employeeCode == element) {
          user.isChecked = true;
          this.selectedEmpList.push({'empId' : user.employeeMasterId,
          'empCode':user.employeeCode,
          'empName':user.fullName,
          'lhgId':user.lmsLeaveSchemaId})
          this.selectedEmpLength = this.selectedEmpLength + 1
          console.log(this.selectedEmpList)
          // sessionStorage.setItem('lmsEmp', JSON.stringify(this.selectedEmpList))
          sessionStorage.setItem('lmsEmp',this.authService.enValue(JSON.stringify(this.selectedEmpList)))
        }
      });
    })
    // this.selectedEmpList.sort(function (x, y) {
    //   let a = x.value.isActive + x.value.fieldName.toUpperCase(),
    //     b = y.value.isActive + y.value.fieldName.toUpperCase();
    //   return a == b ? 0 : a > b ? -1 : 1;
    // });
  }
}
