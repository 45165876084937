<!-- Main body part -->
<div>

    <div class="section-body mt-3">
         <div class="container-fluid">
            <div class="">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <!-- <div class="row new-row-background"> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/roaster-updation']"
                                    (click)="getLeaveAdjustment()">
                                        <div class="stats-box sales"><i class="fa-solid fa-list-ul menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Roster
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/manual-leave-updation']"
                                        (click)="getManualAppEmp()">
                                        <div class="stats-box sales"><i
                                                class="fa-solid fa-calendar-day menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Manual Leave
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4"
                                        [routerLink]="['/lms/lms-input-form-attendance-regularization']"
                                        (click)="getArManualAppEmp()">
                                        <div class="stats-box sales"><i
                                                class="fa-solid fa-user-clock menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Atten. Regularization
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/lms-leave-adjustment-form']"
                                        (click)="getLeaveAdjustment()">
                                        <div class="stats-box sales"><i class="fa-solid fa-sliders menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Leave Adjustment
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/optional-holiday-form']">
                                        <div class="stats-box sales"><i class="fa-solid fa-umbrella menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Optional Holidays
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/lms-input-form-leave-donation']"
                                        (click)="getLeaveAdjustment()">
                                        <div class="stats-box sales"><i
                                                class="fa-solid fa-hand-holding-dollar menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Leave Donation
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-2 mr-4" [routerLink]="['/lms/lms-input-form-leave-encashment']"
                                        (click)="getEncashment()">
                                        <div class="stats-box sales"><i
                                                class="fa-solid fa-file-invoice-dollar menu-icon-css "></i>
                                            <div class="statistics-header text-center mt-1 cursor-pointer">
                                                Leave Encashment
                                            </div>
                                        </div>
                                    </div> -->
                                     <!-- <div class="col-md-3">
                                        <div
                                            class="card align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/manual-leave-updation']"
                                                    href="javascript:void(0)" class=" " (click)="getManualAppEmp()">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Manual Leave
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> -->
                                    <!-- </div> -->
                                    <!-- <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/lms-input-form-attendance-regularization']"
                                                    href="javascript:void(0)" (click)="getArManualAppEmp()">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Atten. Regularization
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/lms-leave-adjustment-form']"
                                                    href="javascript:void(0)" class="" (click)="getLeaveAdjustment()">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Leave Adjustment
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> -->
                                    <!-- </div> -->
                                    <!-- <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/optional-holiday-form']"
                                                    href="javascript:void(0)">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Optional Holidays
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a (click)="getLeaveAdjustment()"
                                                    [routerLink]="['/lms/lms-input-form-leave-donation']"
                                                    href="javascript:void(0)">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Leave Donation
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div
                                            class="card  align-items-center align-items-stretch border-0 text-number-card card-height">
                                            <div class="col-12 py-3 bg-primary rounded-right text-number-card-text">
                                                <a [routerLink]="['/lms/lms-input-form-leave-encashment']"
                                                    href="javascript:void(0)" (click)="getEncashment()">
                                                    <div class=" font-12 font900 mb-3">
                                                        <div class="custom_Label_badge">
                                                            Leave Encashment
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div> 
                                 </div> -->
                                <div class="row mt-25">
                                    <div class="col-md-12">
                                        <div class="table-responsive Custom_Table">
                                            <p-table #dt1 [value]="allEmpList" [paginator]="true" [rows]="10"
                                                [showCurrentPageReport]="true"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                                [rowsPerPageOptions]="[10,25,50,100,200]"
                                                [globalFilterFields]="['employeeCode','fullName','payrollAreaCode','companyName','establishment','employeeType','grade','department','designation1']">
                                                <ng-template pTemplate="caption">
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <label
                                                                class="custom-control custom-checkbox checkbox-center">
                                                                <input type="checkbox"
                                                                    (click)="employeeListCopyPaste($event.target,pasteEmployee)"
                                                                    name="example-inline-radios"
                                                                    class="custom-control-input" />
                                                                <span class="custom-control-label text-black">Employee
                                                                    List</span>
                                                            </label>

                                                        </div>
                                                        <div class="col-md-9">
                                                            <div class="float-right">
                                                                <div class="d-flex">
                                                                     <i class=" fas fa-filter text-purple mr-2 mt-1  font-24"></i> 
                                                                     <span class="p-input-icon-left p-ml-auto">
                                                                        <i class="pi pi-search search-css"></i>
                                                                        <input pInputText type="text"
                                                                            (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                                                            placeholder="Search" />
                                                                    </span>
                                                                    <i class="fa fa-file-excel-o excel-icon-css" (click)="exportToExcel()"></i> 
                                                                 </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </ng-template>

                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th class="text-center">
                                                            <!-- <input type="checkbox" name="select"> -->
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Emp. Code
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="employeeCode"
                                                                        pSortableColumn="employeeCode"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="employeeCode"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Emp. Name
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="fullName"
                                                                        pSortableColumn="fullName"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="fullName"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Payroll Area
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="payrollAreaCode"
                                                                        pSortableColumn="payrollAreaCode"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="payrollAreaCode"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                LMS Area
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="headNature"
                                                                        pSortableColumn="headNature"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="headNature"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th> 
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Company Name
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="companyName"
                                                                        pSortableColumn="companyName"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="companyName"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Emp. Type
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="employeeType"
                                                                        pSortableColumn="employeeType"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="employeeType"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Establishment
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="establishment"
                                                                        pSortableColumn="establishment"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="establishment"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Department
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="department"
                                                                        pSortableColumn="department"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="department"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Grade
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="grade"
                                                                        pSortableColumn="grade"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="grade"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th class="text-center">
                                                            <div class="p-d-flex justify-content-center p-ai-center">
                                                                Designation
                                                                <div class="Sort_Filter_Position">
                                                                    <p-sortIcon field="designation1"
                                                                        pSortableColumn="designation1"></p-sortIcon>
                                                                    <p-columnFilter type="text" field="designation1"
                                                                        display="menu" class="p-ml-auto">
                                                                    </p-columnFilter>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </ng-template>

                                                <ng-template pTemplate="body" let-user1 let-rowIndex="rowIndex">
                                                    <tr class="p-selectable-row">
                                                        <td class="text-center">
                                                            <input type="checkbox" [checked]="user1.isChecked"
                                                                name="select"
                                                                (change)="selectSingleEmp($event.target.checked,user1.employeeMasterId,rowIndex)">
                                                        </td>
                                                        <td class="text-center">
                                                            {{user1.employeeCode}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{user1.fullName}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{user1.payrollAreaCode}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{user1.lmsAreaCode}}
                                                        </td>
                                                        
                                                        <td class="text-center">
                                                            {{user1.companyName}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{user1.employeeType}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{user1.establishment}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{user1.department}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{user1.grade}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{user1.designation1}}
                                                        </td>
                                                    </tr>
                                                </ng-template>

                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="15" class="font-14 font500 text-center text-red">
                                                            <span>No
                                                                Data
                                                                Available</span>
                                                        </td>
                                                    </tr>
                                                 </ng-template> 
                                            </p-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
    <div class="page-loader-wrapper" *ngIf="isLoader">
        <div class="loader-1">
            <div class="loader-outter"></div>
            <div class="loader-inner"></div>
        </div>
    </div>
    <ng-template #pasteEmployee>
        <div class="modal-header">
            <h5 class="modal-title" id="UploadModalLabel">Employee List</h5>
            <button type="button" (click)="modalRef.hide()" class="close" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
            <div class="card">
                <div class="card-body">
                    <div class="row  ">
                        <div class="col-md-12">
                            <textarea (paste)="employeeListPasteData($event)" rows="3" cols="60"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Proceed</button>
            <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
        </div>
    </ng-template>