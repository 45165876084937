import { Component, OnInit ,TemplateRef} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { IBreadCrumb } from '../ibread-crumb';
import { LeftMenuServiceService } from '../left-menu-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { AlertServiceService } from 'src/app/core/services/alert-service.service';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers:[DatePipe]
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[];
  menu: any[];
  breadcrumbList: any = [];
  employeeInfo: { lastLoginDateTime: any; designation1: string; fullName: any; employeeCode: string; time: any; };
  isShowHome: boolean = false;
  selectedLanguage: string;
  lastLoginDateTime:any;
  /****************************punch variable */
  punchDetailList: any = [];
  punchgeoList: any = [];
  isPunchbuttonvisible: boolean = false;
  isPunchin: boolean = false;
  ispunchout: boolean = false;
  isbreak: boolean = false;
  isResume: boolean = false;
  lattitude: any
  longitude: any
  radius: any
  lat: any
  lng: any
  breakButton: any;
  addressDetail: any
  public modalRef: BsModalRef;
  data: any;
  empId: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private datepipe: DatePipe, private alertService: AlertServiceService,
    private leftMenuService: LeftMenuServiceService, private service: AuthService,private modalService: BsModalService,) {
    router.events.pipe(filter(event=>event instanceof NavigationEnd,debounceTime(300))).subscribe((val) => {
      this.listenRouting();
    });
    this.data = this.service.getprivileges();
    this.empId = this.data.UserDetails.employeeMasterId;
  }
  ngOnInit(): void {
      /*********************************************punch Button Api */
      this.getpunchvisible();
/**************************************** */
    let obj = JSON.parse(sessionStorage.getItem('bread'));
    if (obj != undefined) {
      this.breadcrumbList = obj;
      if (this.breadcrumbList.length == 3) {
        this.breadcrumbList.splice(2, 1)
      }
    } else {
      sessionStorage.setItem('bread', JSON.stringify(this.breadcrumbList));
    }
    if(this.selectedLanguage == null || this.selectedLanguage == '' || this.selectedLanguage == undefined){
    this.selectedLanguage =  'English'
  }else{
    this.selectedLanguage =  sessionStorage.getItem('selectedLanguage')
  }
    this.leftMenuService.shareBreadcrumb.subscribe((x: any) => {
      this.breadcrumbList = [];
      if (x.length != undefined) {
        if (x.length != 0) {
          if (x[0].parentMenuName == 'dashboard' || x[0].parentMenuName == 'Employee Dashboard') {
            this.breadcrumbList.push({
              name: x[0].parentMenuName,
              path: x[0].path
            });
          } else {
            this.breadcrumbList.push({
              name: x[0].parentMenuName,
              path: '/menu-forms'
            });
          }
        }
      }
      sessionStorage.setItem('bread', JSON.stringify(this.breadcrumbList))
    })

    this.leftMenuService.shareChildSubject.subscribe(res => {
      let data: any;
      data = res;
      if(data.routerLinkGrandChild != undefined){
        {
          let ind = this.breadcrumbList.findIndex(x => x.name == data.childMenuName)
          if (ind == -1) {
            this.breadcrumbList.push({
              name: data.grandChildName,
    
              path: data.routerLinkGrandChild
            });
          }
        }
      }else{
        if(data.childRouterLink == null){
      let ind = this.breadcrumbList.findIndex(x => x.name == data.childMenuName)      
      if (ind == -1) {
        this.breadcrumbList.push({
          name: data.childMenuName,
          path: '/menu-forms'
        });
      }
    }else{
      let ind = this.breadcrumbList.findIndex(x => x.name == data.childMenuName)
      if (ind == -1) {
        this.breadcrumbList.push({
          name: data.childMenuName,
          path: data.childRouterLink
        });
      }
    }
    }
      sessionStorage.setItem('bread', JSON.stringify(this.breadcrumbList))
    })
    this.service.showHideHome.subscribe(res => {
      this.isShowHome = res;
    })
    let ltData = JSON.parse(sessionStorage.getItem('%lt'))
    if (!ltData) {
      this.getUserDetails()
    } else {
      this.employeeInfo = ltData
    }

    
    let tempRole = this.service.deObject(sessionStorage.getItem('%qr'));
    if(tempRole != undefined){
    this.service.getLastLogin(tempRole.globalUserMasterId).subscribe((res:any)=>{
      this.lastLoginDateTime = res.data.results[0];
      // console.log('lastLoginDateTime',this.lastLoginDateTime);
    }) 
  }
    this.service.lastLoginTime.subscribe((results:any)=>{
      let tempRole = this.service.deObject(sessionStorage.getItem('%qr'));
      this.service.getLastLogin(tempRole.globalUserMasterId).subscribe((res:any)=>{
        this.lastLoginDateTime = res.data.results[0];
        // console.log('lastLoginDateTime',this.lastLoginDateTime);
      })         
    })

  }

  clickBreadcrum(value) {
    let index = this.breadcrumbList.findIndex(x => x.name == value)
    let tempRole = this.service.deObject(sessionStorage.getItem('%qr'));
    if (this.breadcrumbList[index].name != 'dashboard' && this.breadcrumbList[index].name != 'Employee Dashboard') {
      if(value == 'LMS' && tempRole.type == 'Employee'){
        sessionStorage.setItem('%chm','true');
        this.service.showChildMenuHeader.next(true)
        this.router.navigate(['/lms/lms-dashboard']);
      }else{
      if(this.service.shareDataChild.observers.length > 1){
        this.service.shareDataChild.observers.splice(0,1)
      }
      if (index == 0) {
        sessionStorage.setItem('chd','false');
        // this.service.shareDataChild.next(value);
        if (window.location.pathname == '/menu-forms') {
          this.service.callBreadcrumb.next();
        }
      
        this.breadcrumbList.splice(index + 1, (this.breadcrumbList.length - index))
        this.router.navigate([this.breadcrumbList[index].path])
      } else if (index == 1) {
        sessionStorage.setItem('chd','true');
        // this.service.shareDataChild.next(value);
        if (window.location.pathname == '/menu-forms') {
          this.service.callBreadcrumb.next();
        }

        this.breadcrumbList.splice(index + 1, (this.breadcrumbList.length - index))
        this.router.navigate([this.breadcrumbList[index].path])
      } else {
      }
      sessionStorage.setItem('bread', JSON.stringify(this.breadcrumbList))
    }
    } else {
      let getData = this.service.deObject(sessionStorage.getItem('%qr'))
      if (getData.type == 'Employee') {
        this.router.navigate(['/employee-master/employee-dashboard']);
      }
      else {
        this.router.navigate(['Dashboard'])
      }
    }
  }
  listenRouting() {
    let routerUrl: string, routerList: Array<any>, target: any;
    this.router.events.subscribe((router: any) => {
      routerUrl = router.url;
      if (!routerUrl) {
        if (router.snapshot) {
          routerUrl = router.snapshot._routerState.url
        } else { routerUrl = router.routerEvent?.url }
      }
      if (routerUrl != '/menu-forms') {
        if (routerUrl && typeof routerUrl === 'string') {
          target = this.menu;
          let maxChildIndex = this.breadcrumbList.filter(x => x.path == '/menu-forms').length - 1;
          this.breadcrumbList.forEach((element, index) => {
            if (maxChildIndex < index) {
              this.breadcrumbList.splice(index, 1);
            }
          });
          routerList = routerUrl.slice(1).split('/');
          let lastNameInList = routerList[routerList.length - 1]
          if(lastNameInList.includes("?")){
            lastNameInList = lastNameInList.split("?")[0]
          }
          this.breadcrumbList.push({
            // name: routerList[routerList.length - 1],
            name:lastNameInList,
            path: routerUrl
          });
          sessionStorage.setItem('bread', JSON.stringify(this.breadcrumbList));
        }
      }
    });
  }

  onClickHome() {
    sessionStorage.setItem('%chm','false');
    this.service.showChildMenuHeader.next(false)
    let getData = this.service.deObject(sessionStorage.getItem('%qr'))
    if (getData.type == 'Employee') {
      let breadData = [{ "parentMenuName": "Employee Dashboard", "path": "/dashboard" }]
      this.leftMenuService.shareBreadcrumb.next(breadData)
      this.router.navigate(['/employee-master/employee-dashboard']);
    } else {
      let breadData = [{ "parentMenuName": "dashboard", "path": "/dashboard" }]
      this.leftMenuService.shareBreadcrumb.next(breadData)
      this.router.navigate(['Dashboard'])
    }
  }

  getUserDetails() {
    this.service.userDetail().subscribe(res => {
      this.employeeInfo = res.data.results[0]
      let timestamp = this.employeeInfo.lastLoginDateTime * 1000;
      this.employeeInfo.time = new Date(timestamp).toLocaleTimeString();
      sessionStorage.setItem('%lt', JSON.stringify(this.employeeInfo))
    }, err => {
      this.employeeInfo = {
        lastLoginDateTime: '',
        designation1: '',
        fullName: '',
        employeeCode: '',
        time: ''
      }
    })
  }

    /*************************************punch button code */
    async getpunchvisible() {

      this.punchgeoList = [];
      this.punchDetailList = [];
      let date = this.datepipe.transform(new Date(), 'yyyy-MM-dd')
      // data = this.authService.getprivileges();
      // this.empId = data.UserDetails.employeeMasterId;
      const res = await this.service.getpunchbutton(this.empId).toPromise();
      this.punchgeoList = res.data.results[0];
      // console.log(this.punchgeoList)
      if (res.data.results[0].length > 0) {
        this.isPunchbuttonvisible = true;
      }
      // })
      const res1 = await this.service.getpunchindetails(date, this.empId).toPromise();
      this.punchDetailList = res1.data.results[0]
      // console.log(this.punchDetailList)
  
  
    }
    punchModal(templatep3: TemplateRef<any>) {
      this.getpunchvisible()
  
      this.modalRef = this.modalService.show(
        templatep3,
        Object.assign({}, { class: 'gray modal-md' })
      );
      setTimeout(() => {
        this.punchgeoList.forEach(el => {
  
          this.breakButton = el.breakHoursApplicable
          // el.geoMethod='Geo Tagging'
          if (el.punchInOutApplicable == 'Yes') {
            this.isPunchin = true;
            this.ispunchout = true;
          }
          if (el.breakHoursApplicable == 'Yes') {
            this.isbreak = true;
            this.isResume = true
          }
  
        })
  
        if (this.punchDetailList != undefined && this.punchDetailList.length > 0) {
          if (this.punchDetailList[this.punchDetailList.length - 1].punchNature == 'Punch Out') {
            this.ispunchout = false;
            this.isPunchin = true
          } else if (this.punchDetailList[this.punchDetailList.length - 1].punchNature == 'Punch In') {
            this.isPunchin = false;
            this.ispunchout = true
          }
          if (this.punchDetailList[this.punchDetailList.length - 1].punchNature == 'break') {
            this.isbreak = false;
            this.isResume = true
          }
  
        } else {
          this.isPunchin = true;
          this.isbreak = true;
          this.ispunchout = false;
          this.isResume = false
  
        }
      }, 1200);
    }
    onpunchin(action) {
  
      this.lattitude = ''
      this.longitude = ''
      this.radius = ''
      if (this.punchgeoList.length > 0) {
        this.punchgeoList.forEach(el => {
          this.lattitude = el.latitude
          this.longitude = el.longitude
          this.radius = el.radiusInMeters
          if (el.geoMethod == 'Geo Tagging') {
            this.getlocation(action);
            // this.punchsave(action);
          } else if (el.geoMethod == 'Geo Fencing') {
            this.calculatedistance(action);
          } else if (el.geoMethod == 'Geo TaggingfencingCombo') {
            let geoFencingEnableOn = this.punchgeoList[0].geoFencingEnableOn
            switch (geoFencingEnableOn) {
              case 'Shift Start':
                if (action == 'Punch In') {
                  this.calculatedistance(action);
                } else {
                  this.getlocation(action);
  
                }
                break;
              case 'Shift End':
                if (action == 'Punch Out') {
                  this.calculatedistance(action);
                } else {
                  this.getlocation(action);
  
                }
                break;
              case 'Both':
                if (action == 'Punch In' || action == 'Punch Out') {
                  this.calculatedistance(action);
                } else {
                  this.getlocation(action);
  
                }
                break;
              case 'Any Time':
                this.calculatedistance(action);
                break;
            }
            // }
  
          }
        })
  
      }
    }
    calculatedistance(action) {
      this.addressDetail = ''
      this.lat = ''
      this.lng = ''
      window.navigator.geolocation.getCurrentPosition(position => {
  
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        // console.log(this.lat,this.lng)
        if (this.lat != '') {
          let distanceInmMeter = this.getDistance(this.lattitude, this.longitude, this.lat, this.lng)
          //
  
          if (distanceInmMeter <= this.radius) {
            this.service.addrsssdetails(this.lat, this.lng, this.lattitude, this.longitude).subscribe((res: any) => {
              this.addressDetail = res.data.results[0].origin_addresses[0];
              this.punchsave(action);
            })
          } else {
            this.alertService.sweetalertWarning('You are not in Office Premises.');
          }
        }
      }, error => {
        this.alertService.sweetalertWarning('You have not allowed Location Permission, so you are not allowed to do Punch In Out');
      });
  
  
    }
    getlocation(action) {
      this.lat = ''
      this.lng = ''
      window.navigator.geolocation.getCurrentPosition(position => {
  
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        console.log(this.lat, this.lng)
        if (this.lat != '') {
  
          this.service.addrsssdetails(this.lat, this.lng, this.lat, this.lng).subscribe((res: any) => {
            this.addressDetail = res.data.results[0].origin_addresses[0];
            this.punchsave(action);
          })
  
        }
      }, error => {
        this.alertService.sweetalertWarning('In Browser Location permission is Off,  request you to On the permission to do punch In');
      }, {
        timeout: 10000,
        enableHighAccuracy: true,
        maximumAge: 0
      });
  
    }
  
    getDistance(lat1, lng1, lat2, lng2) {
  
  
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = this.deg2rad(lat1 - lat2);
      const dLon = this.deg2rad(lng1 - lng2);
  
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
  
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
  
      return distance * 1000;
  
      // return distance;
    }
  
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    }
    punchsave(action) {
  
      let currenttime = this.datepipe.transform(new Date(), 'HH:mm:ss')
      //  let Savedata=[]
      let Savedata = [{
        "date": this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
        "employeeMasterId": this.empId,
        "punchDate": this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
        "latLongAccuracy": 0,
        "punchNature": action,
        "latitude": this.lat,
        "latitudeAddress": this.addressDetail,
        "lmsAttendanceMachineMasterInId": 0,
        "lmsAttendanceMachineMasterOutId": 0,
        "lmsPunchInOutId": 0,
        "longitude": this.lng,
        "longitudeAddress": this.addressDetail,
        "time": currenttime,
        "source": 'Web',
        "sync": 0,
      }];
      // Savedata.push(obj)
      // console.log(Savedata)
      this.service.savePunch(Savedata).subscribe((res: any) => {
        this.alertService.sweetalertMasterSuccess(action + ' ' + 'Saved Successfully', '')
        // console.log(res.status.code)
        if (res.status.code == 'Success') {
          switch (action) {
            case 'Punch In':
              this.isPunchin = false;
              this.ispunchout = true
  
              break;
            case 'Punch Out':
              this.isPunchin = true;
              this.ispunchout = false
  
              break;
            case 'Break':
              this.isbreak = false
              this.isResume = true
  
              break;
            case 'Resume':
  
              this.isbreak = true
              this.isResume = false
              break;
          }
        }
      }, error => {
        this.alertService.sweetalertError(error["error"]["status"]["messsage"]);
      })
    }
}